<template>
  <svg
    width="251"
    height="200"
    viewBox="0 0 251 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M207.5 65a7 7 0 1 1 0 14h-40a7 7 0 1 1 0 14h22a7 7 0 1 1 0 14h-10.174c-4.874 0-8.826 3.134-8.826 7 0 2.577 2 4.911 6 7a7 7 0 1 1 0 14h-83a7 7 0 1 1 0-14h-39a7 7 0 1 1 0-14h40a7 7 0 1 0 0-14h-25a7 7 0 1 1 0-14h40a7 7 0 1 1 0-14h98Zm0 28a7 7 0 1 1 0 14 7 7 0 0 1 0-14Z"
      fill="#DFDFFB"
    />
    <path
      d="M121 133c18.502 0 33.5-14.998 33.5-33.5 0-18.501-14.998-33.5-33.5-33.5-18.502 0-33.5 14.999-33.5 33.5 0 18.502 14.998 33.5 33.5 33.5Z"
      fill="#F3F7FF"
      stroke="#02B"
      stroke-width="2.5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M115.632 125.494c1.759.325 3.548.493 5.368.506 14.636 0 26.5-11.864 26.5-26.5 0-14.635-11.864-26.5-26.5-26.5-3.76 0-7.336.783-10.576 2.195a26.607 26.607 0 0 0-13.028 12.247A26.387 26.387 0 0 0 94.5 99.5a26.403 26.403 0 0 0 6.684 17.596"
      fill="#fff"
    />
    <path
      d="M115.632 125.494c1.759.325 3.548.493 5.368.506 14.636 0 26.5-11.864 26.5-26.5 0-14.635-11.864-26.5-26.5-26.5-3.76 0-7.336.783-10.576 2.195a26.607 26.607 0 0 0-13.028 12.247A26.387 26.387 0 0 0 94.5 99.5a26.403 26.403 0 0 0 6.684 17.596m3.113 2.979a26.491 26.491 0 0 0 7.204 4.172"
      stroke="#02B"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path d="m148.5 126 6 6" stroke="#02B" stroke-width="2.5" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M153.53 131.03a4.848 4.848 0 0 0 0 6.854l11.086 11.086a4.848 4.848 0 0 0 6.854-6.854l-11.086-11.086a4.848 4.848 0 0 0-6.854 0Z"
      fill="#E8F0FE"
      stroke="#02B"
      stroke-width="2.5"
    />
    <path
      d="m158.5 133 11 11"
      stroke="#fff"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M114.5 88c0 11.598 9.402 21 21 21 2.278 0 4.472-.363 6.526-1.034-3.353 8.321-11.503 14.195-21.026 14.195-12.515 0-22.66-10.146-22.66-22.661 0-11.34 8.33-20.735 19.205-22.399A20.913 20.913 0 0 0 114.5 88Z"
      fill="#E8F0FE"
    />
    <path
      d="M121.5 81c-1.273 0-2.518.125-3.721.364m-3.634 1.112C107.304 85.351 102.5 92.114 102.5 100"
      stroke="#02B"
      stroke-width="2.5"
      stroke-linecap="round"
    />
    <path
      d="M174.676 99.777H166.5M181 92h-17.176H181Zm7 0h-2.221H188ZM84.676 121.777H76.5M80 113H62.824 80Zm-23 0h-4.221H57Z"
      stroke="#02B"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
