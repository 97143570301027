<template>
  <section id="about" class="about">
    <div class="container">
      <div class="row">
        <div class="col-md-7 aboutsection">
          <template v-if="info.title">
            <div class="col-md-12 col-12 pb-2">
              <h2 style="color: #0b416c">{{ info.title }}</h2>
              <p>Date: {{ info.dated_on }}</p>
              <hr />
              <p v-if="info.lectionary">Lectionary: {{ info.lectionary }}</p>
              <audio
                v-if="info.gdrive_audio_id"
                controls
                class="mb-2"
                id="audio-preview"
              >
                <source
                  :src="
                    `http://docs.google.com/uc?export=open&id=` +
                    info.gdrive_audio_id
                  "
                  type="audio/mp3"
                />
              </audio>
            </div>
            <div class="section-title">
              <p>{{ info.content }}</p>
            </div>
          </template>
          <template v-else>
            <div class="noRecord">
              <ICON_NoRecordFound />
              <div>No reflections found</div>
            </div>
          </template>
        </div>
        <ContentByFilter
          @update:lang="HandleEmitLang"
          @update:date="HandleEmitDate"
          :sel_lang="lang"
          :sel_date="dated_on"
        />
      </div>
    </div>
  </section>
</template>

<script>
import ContentByFilter from "@/components/ContentByFilter.vue";
import ICON_NoRecordFound from "@/assets/icons/NoRecordFound.vue";
import axios from "axios";
export default {
  components: {
    ContentByFilter,
    ICON_NoRecordFound,
  },
  data() {
    let today = new Date().toISOString().split("T")[0];
    return {
      lang: "en",
      dated_on: today,
      info: {
        title: "",
        dated_on: "",
        lectionary: "",
        gdrive_audio_id: "",
        content: "",
      },
    };
  },
  mounted() {
    this.ActionUpdateStore();
  },
  methods: {
    HandleEmitLang(code) {
      this.lang = code;
      this.ActionUpdateStore();
    },
    HandleEmitDate(value) {
      this.dated_on = value;
      this.ActionUpdateStore();
    },

    ActionUpdateStore() {
      const url =
        "https://qa.api.vezham.com/widgets/stringcms/v1/pages/lord_is_my_shepherd/collections/reflections/entities/views";
      const config = {
        headers: {
          "Content-Type": "application/json",
          "X-WORKSPACE-WALLET-ADDRESS":
            "0wxicxRqQFNtPYbC2l9LxfL1rKOH6lmogh2FDQNuYw0HKtXgXtt++M93SaKxEceXAWrutZE5knj0nAkeho/Zg==",
        },
      };
      let data = {
        language_code: this.lang,
      };

      if (this.dated_on != "") {
        data.filters = [
          {
            type: "date",
            field: "dated_on",
            operator: "equals",
            value: this.dated_on,
          },
        ];
      }
      axios
        .post(url, data, config)
        .then(({ data }) => {
          if (data.meta.status != "success") {
            this.info = {};
            return;
          } else if (data.data.length == 0) {
            this.info = {};
            return;
          }

          this.info = data.data[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.noRecord {
  text-align: center;
}
.about {
  padding: 30px;
  background: #f9f9f9;
  position: relative;
}
.aboutsection {
  padding: 30px;
}
.about:before {
  content: "";
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
.about .container {
  position: relative;
}

.about .content h2 {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 26px;
}

@media (max-width: 992px) {
  .about {
    padding: 30px;
  }
}
</style>
